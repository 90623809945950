import React from "react";
import { Canvas } from "@react-three/fiber";
import {
  GizmoHelper,
  GizmoViewport,
  Grid,
  OrbitControls,
} from "@react-three/drei";
import { useControls } from "leva";
import { SphereWithShader } from "../../tassa/_components/_three_components/_normal_map";

export default function App() {
  const { xy, z } = useControls({
    xy: { x: 0, y: 0 },
    z: 1,
  });
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 10,
      }}
    >
      <Canvas camera={{ position: [0, 1, 1] }}>
        <ambientLight intensity={0.25} />
        <pointLight
          position={[xy.x * 10, z, xy.y * 10]}
          direction={[0, 0, -1]}
          angle={0.5}
          penumbra={1}
        />
        <mesh position={[xy.x * 10, z, xy.y * 10]}>
          <sphereGeometry args={[0.05, 64, 64]} color="red" />
          <meshStandardMaterial color="red" />
        </mesh>
        <mesh position={[0, 0, 0]}>
          <boxGeometry args={[3, 3, 0.3, 10, 10, 10]} />
          <meshStandardMaterial color="red" />
        </mesh>
        <Grid
          position={[0, 0, 0]}
          args={[10, 10]}
          {...{
            cellSize: 0.1,
            cellThickness: 0.6,
            cellColor: "#6f6f6f",
            sectionSize: 0.5,
            sectionThickness: 1.5,
            sectionColor: "#23aaff",
            fadeDistance: 10,
            fadeStrength: 1,
            followCamera: false,
            infiniteGrid: true,
          }}
        />
        <SphereWithShader />
        <OrbitControls  />
        <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
          <GizmoViewport
            axisColors={["#9d4b4b", "#2f7f4f", "#3b5b9d"]}
            labelColor="white"
          />
        </GizmoHelper>
      </Canvas>
    </div>
  );
}
