import React, { useEffect, useMemo, useRef } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import {
  DataTexture,
  NoColorSpace,
  TangentSpaceNormalMap,
  TextureLoader,
} from "three";
import { useControls } from "leva";
import { Movable } from "./_movables";
import { height2normal } from "./_normal_map_utils";

const isLoaded = (image) => image.complete && image.naturalHeight !== 0;

export function SphereWithShader({ width = 1144, height = 767 }) {
  const { camera, gl, scene } = useThree();
  const pointLightRef = useRef();
  const meshRef = useRef();

  const { displacementScale, normalScale, normalBias } = useControls({
    displacementScale: { value: 1, step: 0.01 },
    normalScale: { value: [1, 1], step: 0.01 },
    normalBias: { value: [0, 0], step: 0.01 },
  });

  const displacementTexture = useLoader(
    TextureLoader,
    "http://localhost:8012/local/ei_common.png"
  );
  displacementTexture.colorSpace = NoColorSpace;

  // const gtNormalTexture = useLoader(
  //   TextureLoader,
  //   "http://localhost:8012/local/ei_normal_map.png"
  // );
  // gtNormalTexture.colorSpace = NoColorSpace;

  const normalData = useMemo(
    () => new Uint8ClampedArray(4 * width * height),
    [width, height]
  );

  // DataTexture has the Y-axis flipped vs images.
  const normalTexture = useMemo(() => {
    const texture = new DataTexture(normalData, width, height);
    // flip the image when sending to GPU.
    texture.flipY = true;
    // setting it here, not on the texture
    texture.colorSpace = NoColorSpace;
    return texture;
  }, [width, height]);

  useEffect(() => {
    if (!isLoaded(displacementTexture.source.data)) return;

    console.log("flipY", normalTexture.flipY);
    height2normal(
      displacementTexture.source.data,
      normalData,
      normalTexture.flipY
    );
    normalTexture.image = { data: normalData, width, height };
    normalTexture.colorSpace = NoColorSpace;
    normalTexture.needsUpdate = true;
  }, [displacementTexture.source.data, width, height]);

  return (
    <group>
      <Movable ref={pointLightRef} position={[0, 0.8, 5]}>
        <pointLight color={0xffffff} intensity={3} />
        <mesh shadow depthTest>
          <sphereGeometry attach="geometry" args={[0.05, 64, 64]} />
          <meshBasicMaterial attach="material" color="red" />
        </mesh>
      </Movable>
      <mesh ref={meshRef} shadow depthTest castShadow position={[0, 0, 1]}>
        <planeGeometry
          attach="geometry"
          args={[(10 * 1144) / 767, 10, 1144, 767]}
        />
        <meshPhongMaterial
          attach="material"
          // color="white"
          metalness={0.0}
          displacementMap={displacementTexture}
          displacementScale={displacementScale}
          displacementBias={-0.3 * displacementScale}
          // normalMap={gtNormalTexture}
          normalMap={normalTexture}
          // normalMap-colorSpace={NoColorSpace}
          normalScale={normalScale}
          normalBias={normalBias}
          normalMapType={TangentSpaceNormalMap}
        />
      </mesh>
    </group>
  );
}
